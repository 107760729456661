






import '@/assets/styles/admin.scss';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'FullLayout',
})
export default class FullLayout extends Vue {}
